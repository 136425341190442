import type { FC } from 'react';
import type { EntityData, PublishingOptionType, StatusType } from '@ContractBuilder/types';

import { ContractTools } from '../views/ContractTools';

interface ContractToolsControllerProps {
  publishingOptions: PublishingOptionType[];
  handleStatusUpdate: (status: StatusType) => Promise<void>;
  submission: EntityData;
  isLoading: boolean;
  isViewingRevisionHistory: boolean;
}

export const ContractToolsController: FC<ContractToolsControllerProps> = ({
  publishingOptions,
  handleStatusUpdate,
  submission,
  isLoading,
  isViewingRevisionHistory,
}) => {
  return (
    <ContractTools
      isLoading={isLoading}
      isViewingRevisionHistory={isViewingRevisionHistory}
      onStatusUpdate={handleStatusUpdate}
      publishingOptions={publishingOptions}
      submission={submission}
    />
  );
};
