import type { FC, MouseEventHandler, ReactNode } from 'react';
import type { UseQueryResult } from 'react-query';
import type { SelectMenuItem } from '@components/SelectMenu/TreeView';
import type {
  BlockType,
  BlockVisibilityConfig,
  BrandingDetailsType,
  BrandingStatus,
  BrandingType,
  ClauseType,
  TemplateStatus,
} from '@root/functions/validation';
import type { ErrorObject as JSONSchemaError } from 'ajv';
import type { PDFMargin } from 'puppeteer-core';

import type { MrcExtractionResult } from './api';
import type { GenericObject, ResourceBlock, ResourceSection } from './base';
import type { ClauseTypes, DataSet } from './resources';
import type { JSONSchema, JSONSchemaProperties } from './schema';

export type FormError = JSONSchemaError & {
  ownKey: string;
};

export * from './base';
export * from './database';
export * from './payloads';
export * from './resources';
export * from './schema';
export * from './utilities';

export type { BlockType, BlockVisibilityConfig, BrandingDetailsType, BrandingStatus, BrandingType, ClauseType };

export type EntityDocumentType =
  | 'MRC'
  | 'EOC'
  | 'EOC_BINDER'
  | 'EOC_OPEN_MARKET'
  | 'EOC_OPEN_MARKET_BERMUDA'
  | 'BERMUDA_MRC'
  | 'BERMUDA_MRC_WHITE_LABEL'
  | 'BERMUDA_DECLARATION'
  | 'BERMUDA_DECLARATION_RELM_EO_CYBER'
  | 'TEMPLATE_MRC';

export type LanguageDocumentType = 'mrc' | 'generic';

export type MRCRiskDetails = any;

export type EOCBinderRiskDetails = any;

export type EOCOpenMarketRiskDetails = any;

export type EDDocumentStatus = 'PRE-DRAFT' | 'DRAFT' | 'In Progress' | '';
export type EDDocumentVisibilityStatus = 'DRAFT' | 'PUBLISHED' | 'ARCHIVED' | '';

export const SUBMISSION_STATUSES = ['DRAFT', 'FINAL', 'ARCHIVED'];
export const TEMPLATE_STATUSES = ['DRAFT', 'PUBLISHED', 'ARCHIVED'];

export type PdfDocumentType =
  | 'template-document'
  | 'submission-document'
  | 'endorsement-document'
  | 'endorsement-summary'
  | 'branding-document';

export type PdfDocument = {
  name: string;
  subtype?: string;
  type: PdfDocumentType;
  version: number;
  path?: string;
};

export type PdfGeneratedDocumentName = {
  shortName: string;
  longName: string;
};

export type Submission = {
  clauses?: any[];
  created_at: string;
  /** @deprecated Attr `document_name` is deprecated - use `documents` instead */
  document_name?: string;
  document_type: EntityDocumentType;
  document_version?: number; // pdf documents version
  documents?: PdfDocument[]; // pdf documents
  editor_content: GenericObject<string>;
  id: string;
  /** Layout holding IDs of sections belonging to a submission in order **/
  layout: string[];
  pk: string;
  risk_details: MRCRiskDetails | EOCBinderRiskDetails | EOCOpenMarketRiskDetails;
  sk: string;
  status: EDDocumentStatus;
  tenant_id?: string;
  updated_at?: string;
  version: number;
};

export type submissionId = string;
export type ResourceName = 'submissions' | 'templates' | `submissions#${submissionId}`;

export type ServerResponse<TData = unknown> = {
  data: TData;
  schemas: JSONSchema[];
};

export type Address = {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zipcode: string;
};

export type TemplateUser = {
  sub: string;
  iss: string;
  given_name: string;
  origin_jti: string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: string;
  exp: string;
  iat: string;
  family_name: string;
  jti: string;
  email: string;
  'cognito:username': string;
  'custom:tenantName': string;
  'custom:tenantId': string;
  'custom:subTenantIds': string;
  'custom:permissions': string;
  'custom:role': string;
};

export type BaseUser = {
  attributes?: Record<string, any>;
  companyId?: string;
  companyName?: string;
  email: string;
  firstName: string;
  lastName: string;
  organisationName?: string;
  role: UserRole;
  signInUserSession?: { idToken: { payload: string } };
  sub?: string[];
  teamName?: string;
  tenantId: string;
  tenantIds: number[];
  updatedAt?: number;
  username: string;
};

export interface AmplifyUser extends BaseUser {
  signInUserSession: { idToken: { payload: string } };
  attributes: {
    given_name: string;
    family_name: string;
    email: string;
    sub: string[];
    'custom:role': string;
    'custom:tenantId': string;
    'custom:subTenantIds': string[];
    'custom:tenant_ids': string;
  };
  username: string;
}

export type UISchema = {
  enum: GenericObject[];
  properties: GenericObject;
} & GenericObject<string>;

export type Clause = {
  updated_at?: string;
  created_at?: string;
  deleted_at?: string | null;
  content: string;
  index: number;
  description: string;
  id: string;
  type: ClauseType;
  is_edited?: boolean;
  version?: number;
  sk?: string;
  pk?: string;
};

export type ClauseShort = Pick<
  Clause,
  'content' | 'id' | 'type' | 'description' | 'deleted_at' | 'is_edited' | 'created_at'
>;
export type ClauseLibrary = ClauseShort[];

export type TemplateRiskDetails =
  | {
      umr: string;
      inception_date: string;
      insured_name: string;
      expiry_date: string;
    }
  | {
      umr: string;
    };

export type Template = {
  /** @deprecated Attr `branding` is deprecated - use `branding_id` instead */
  branding?: string;
  branding_id?: string;
  class_of_business?: string;
  copy_from_template_id?: string;
  created_at: string;
  /** @deprecated Attr `document_name` is deprecated - use `documents` instead */
  document_name?: string;
  document_type: EntityDocumentType;
  document_version?: number; // pdf documents version
  documents?: PdfDocument[]; // pdf documents
  editor_content: GenericObject<string>;
  id: string;
  name?: string;
  pk: string;
  risk_details: TemplateRiskDetails;
  schema_id?: string;
  sk: string;
  status: TemplateStatus;
  updated_at?: string;
  user: TemplateUser;
  version: number;
  visibility_status: EDDocumentVisibilityStatus;
};

export type TemplateShort = Omit<
  Template,
  | 'user'
  | 'risk_details'
  | 'clauses'
  | 'document_type'
  | 'document_name'
  | 'document_url'
  | 'documents'
  | 'editor_content'
  | 'created_at'
> & {
  created_at: string;
  user?: Pick<TemplateUser, 'email'>;
  risk_details?: Pick<TemplateRiskDetails, 'umr'>;
};

export type ClausePayload = Partial<Omit<Clause, 'version' | 'pk' | 'sk' | 'updated_at' | 'is_edited'>>;

export type UseQueryRefetchFn<TData = unknown, TError = unknown> = (options?: {
  throwOnError: boolean;
  cancelRefetch: boolean;
}) => Promise<UseQueryResult<TData, TError>>;

export type UseQueryStatus = 'idle' | 'error' | 'loading' | 'success';

export interface UIComponentBase {
  className?: string;
  children?: ReactNode;
  'data-testid'?: string;
}

export type ElementSize = 'sm' | 'md' | 'lg';
export type ElementSizes = Record<ElementSize, string>;

interface SvgProps {
  stroke?: string;
  strokeWidth?: number | string;
  fill?: string;
}

export type CustomSvgComponent = FC<SvgProps>;

export type UIContext = {
  contractData?: {
    submission?: {
      base?: {
        effective_from?: string;
        expiry_date?: string;
        inception_date?: string;
      };
    };
  };
};

export type UIFormValues = GenericObject<string | GenericObject<UIInputValue>>;

export type UIChildrenMappingProps = {
  autoFocus?: boolean;
  ctx?: UIContext;
  validationErrors: FormError[] | null;
  showQuestionId?: boolean;
  showQuestionKey?: boolean;
  onChange: UIOnChangeFn<string | number>;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  hideErrors?: boolean;
};

export interface UIMapChildrenFnArgs extends UIChildrenMappingProps {
  isReadOnly?: boolean;
  parentKey: string;
  parentSchema: JSONSchema;
  useParentKey?: boolean;
}

export type UIMapChildrenFn = (args: UIMapChildrenFnArgs) => ReactNode;

export type UIInputValue<T = string> = T | undefined;

export type UIOnChangeFn<T = string> = (value: UIInputValue<T>, name: string) => void;

export type UISelectionType<T = string> = { name: string; value?: T; isDisabled?: boolean };

export type SortDirection = 'asc' | 'desc';

export type SortDefaultFieldValue = string | number | boolean;

export type SortFieldValueNormalizerFn = (fieldValue: string, field: any) => any;

export type SortOrder = {
  fieldName: string;
  sortDirection: SortDirection;
  defaultFieldValue: SortDefaultFieldValue;
  fieldValueNormalizer?: SortFieldValueNormalizerFn;
};

export type ToggleSortOrderDirectionFn = (
  fieldName: SortOrder['fieldName'],
  fieldValueNormalizer?: SortFieldValueNormalizerFn,
) => MouseEventHandler<HTMLAnchorElement> | undefined;

export type UrlFilter = { key: string; value: string[] | boolean };

export type SetIsLoading = (isLoading: boolean) => void;

export type Column = {
  label: string;
  key: string;
  fallbackKeys?: string[];
  sortable?: boolean;
  normalizer?: SortFieldValueNormalizerFn;
  type?: 'link';
  href?: string;
  onClick?: any;
  render?: (item: any, tags?: Tag[], setIsLoading?: SetIsLoading) => any;
  formatter?: (fieldValue: any) => any;
  target?: '_blank' | '_self' | '_parent' | '_top';
  rel?: string;
  headingCellClassName?: string;
  headingLinkClassName?: string;
  dataCellClassName?: string;
  linkClassName?: string;
};

export type DropdownFilter = {
  type: 'dropdown';
  label: string;
  key: string;
  placeholder: string;
  options: readonly string[] | readonly UISelectionType[];
  optionsMapping?: { value: string; label: string; predicate?: (item: any) => boolean }[];
  isPredicateFilter?: boolean;
  selected: string[];
  className?: string;
  wrapperClassName?: string;
};

export type CheckboxFilter = {
  type: 'checkbox';
  isChecked: boolean;
  onChange: any;
  label: string;
  key: string;
  className?: string;
  wrapperClassName?: string;
};

export type Filter = DropdownFilter | CheckboxFilter;

export type Action = {
  key: string;
  label: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
};

export type AnyCase<T extends string> = string extends T
  ? string
  : T extends `${infer F1}${infer F2}${infer R}`
  ? `${Uppercase<F1> | Lowercase<F1>}${Uppercase<F2> | Lowercase<F2>}${AnyCase<R>}`
  : T extends `${infer F}${infer R}`
  ? `${Uppercase<F> | Lowercase<F>}${AnyCase<R>}`
  : '';

export type Tag = {
  version: number;
  sk: string;
  id: string;
  pk: string;
  label: string;
  updated_at?: string;
  created_at: string;
};

export type TagShort = Pick<Tag, 'id' | 'label' | 'created_at'>;

export type BrandingDatapointId =
  | 'metadata'
  | 'ref'
  | 'name'
  | 'teams' // skip for PPL
  | 'class_of_business'
  | 'date_created_at'
  | 'time_created_at'
  | 'date_updated_at'
  | 'time_updated_at'
  | 'created_by.name'
  | 'datapoints'
  | 'data_items.risk_details.unique_market_reference.unique_market_reference'
  | 'data_items.risk_details.unique_market_reference.placing_broker_unique_identifier'
  | 'page_counter'
  | 'pageNumber'
  | 'totalPages';

export enum SummaryDatapointId {
  // Form datapoints
  Policyholders = 'data_items.risk_details.policyholder',
  UniqueMarketReference = 'data_items.risk_details.unique_market_reference.unique_market_reference',
  OriginalInsured = 'data_items.risk_details.original_insured',
  // Endorsement datapoints
  DateCreatedAt = 'created_at',
  DateUpdatedAt = 'updated_at',
  EffectiveDate = 'effective_date',
  EndorsementReason = 'reason',
  EndorsementReference = 'reference',
  EndorsementType = 'type',
  ExpiryDate = 'expiry_date',
  PremiumAmount = 'additional_premium.amount',
  PremiumChange = 'premium_change',
  PremiumCurrency = 'additional_premium.currency',
  TimeCreatedAt = 'created_at_time',
  TimeUpdatedAt = 'updated_at_time',
}

export enum StandardisedSectionName {
  RiskDetails = 'risk_details',
  Information = 'information',
  SecurityDetails = 'security_details',
  SubscriptionAgreement = 'subscription_agreement',
  FiscalAndRegulatory = 'fiscal_and_regulatory',
  BrokerRemunerationAndDeductions = 'broker_remuneration_deductions',
}

export interface Section {
  id: string;
  label: string;
  should_hide_title_in_pdf?: boolean;
  should_reset_page_counter: boolean;
  standardised_name?: StandardisedSectionName;
  layout: string[];
}

export enum UserRole {
  Admin = 'admin',
  Superadmin = 'superadmin',
  User = 'user',
  TemplateManager = 'template_manager',
}

export interface User extends DataSet<string | undefined> {
  email?: string;
  name: string;
  id: string;
  role: UserRole;
  tenant_id: string;
  teams?: string;
  organization_id?: string;
}

export enum DocumentMode {
  SUBMISSIONS = 'submissions',
  TEMPLATES = 'templates',
  ENDORSEMENTS = 'endorsements',
  BRANDING = 'branding',
  OTHER = 'other',
}

export type DocumentOperation = 'edit' | 'status-change';

export type PdfHtmlRender = {
  margin?: PDFMargin;
  templates: string[];
  templateHeader?: string;
  templateFooter?: string;
  brandingId?: string;
  brandingVersion?: number;
};

export type PdfStepFunctionEvent = {
  resourceName: string;
  resourceId: string;
  tenantId?: string;
  webhookType?: WebhookType;
  webhookPayload?: unknown;
  pdfStatus?: 'success' | 'skip';
};

export type WebhookType = 'basic' | 'sns' | 'unknown';

export type WebhookConfig =
  | {
      webhookEnabled: boolean;
      webhookType: 'basic';
      webhookUrl: string;
      webhookUsername: string;
      webhookPassword: string;
      webhookPasswordSSMKeyName?: string;
    }
  | {
      webhookEnabled: boolean;
      webhookType: 'sns';
      webhookARN: string;
    }
  | {
      webhookEnabled: false;
      webhookType: 'unknown';
    };

export type CognitoConfig = {
  linkedLogo: string;
  emailSender: string;
  appName: string;
  appDescription: string;
  client: string;
  footerAddress: string;
};

export type Team = {
  id: number;
  name: string;
};

export type TenantName =
  | 'artificial'
  | 'ct-edbroking'
  | 'ct-lockton'
  | 'ct-bms'
  | 'ct-ajg'
  | 'ct-howden'
  | 'ct-ppl'
  | 'ct-aon';

export type TenantPdfConfig = {
  format: 'A0' | 'A1' | 'A2' | 'A3' | 'A4' | 'A5' | 'A6' | 'Ledger' | 'Legal' | 'Letter';
  documents: Record<'templates' | 'submissions' | 'endorsements' | 'brandings', PdfDocumentType[]>;
  watermark?: WatermarkConfig;
};

export type WatermarkConfig = {
  classNames?: string[];
  css?: string;
  innerHTML?: string;
};

export type TenantConfig = {
  cognito: CognitoConfig;
  headerLogo: string;
  headerLogoClasses: string;
  headerLogoHeight?: string;
  headerLogoWidth?: string;
  isDataExtractionEnabled?: boolean;
  isEndorsementEnabled?: boolean;
  isAttachmentsEnabled?: boolean;
  isCommentsEnabled?: boolean;
  isRevisionsEnabled?: boolean;
  isBrandingEnabled?: boolean;
  isSSOEnabled?: boolean;
  logo: string;
  name: string;
  pdf: TenantPdfConfig;
  teams?: Team[];
};

export enum KeyCode {
  // expand as needed
  Esc = 'Escape',
  Enter = 'Enter',
  Space = 'Space',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
}

export type Environment = 'local' | 'ephemeral' | 'dev' | 'demo' | 'staging' | 'uat' | 'prod';

export type ClientRef = 'ART';

export enum BlockError {
  BlockEmpty = 'block-empty',
  DatapointEmpty = 'datapoint-empty',
  VariationNotSelected = 'variation-not-selected',
}

export type TemplateSchemaField = {
  id: string;
  isRequired: boolean;
  section_id: string;
  title?: string;
  value: Partial<JSONSchemaProperties>;
};

/** Block visibility **/
const BLOCK_VISIBILITY_OPERATORS = ['IS', 'IS NOT', 'IS EMPTY', 'IS NOT EMPTY'] as const;
const BLOCK_VISIBILITY_MATCH = ['AND', 'OR'] as const;
const BLOCK_VISIBILITY_MODE = ['SHOW', 'HIDE', 'AUTOSELECT'] as const;

export interface BlockVisibilityOutput {
  mode: BlockLevelVisibilityConfig['mode'];
  match?: BlockLevelVisibilityConfig['match'] | undefined;
  conditions: BlockVisibilityResult['conditions'];
  result: boolean;
  info: string | undefined;
  id?: string;
}

export type BlockVisibilityOperators = (typeof BLOCK_VISIBILITY_OPERATORS)[number];
export type BlockVisibilityMatch = (typeof BLOCK_VISIBILITY_MATCH)[number];
export type BlockVisibilityMode = (typeof BLOCK_VISIBILITY_MODE)[number];

export type BlockVisibilityValueType = string | string[] | number | boolean;

export type BlockVisibilityCondition = {
  field: string;
  operator: BlockVisibilityOperators;
  value?: BlockVisibilityValueType;
};

export interface BlockLevelVisibilityConfig {
  conditions: BlockVisibilityCondition[];
  match?: BlockVisibilityMatch;
  mode: BlockVisibilityMode;
}
export interface VariationLevelVisibilityConfig {
  conditions: BlockVisibilityCondition[];
  id: string;
  match?: BlockVisibilityMatch;
  mode: BlockVisibilityMode;
}

export type BlockVisibilityConditionWithResult = BlockVisibilityCondition & { result: boolean };

export interface SelectMenuItemResult extends Omit<SelectMenuItem, 'type'> {
  title?: string;
  type?: string;
}

export interface BlockVisibilityResult extends BlockLevelVisibilityConfig {
  conditions: BlockVisibilityConditionWithResult[];
}

export type LeafPaths<T, P extends string = ''> = {
  [K in keyof T]: T[K] extends object
    ? K extends string
      ? `${P}${K}.${LeafPaths<T[K]>}`
      : never
    : K extends string
    ? `${P}${K}`
    : never;
}[keyof T];

export type NodesPaths<T, K extends string = ''> = T extends PropertyKey
  ? K
  : keyof T extends T
  ? K
  : {
      [P in keyof T]: P extends string
        ? K extends ''
          ? NodesPaths<T[P], `${P}`>
          : K | NodesPaths<T[P], `${K}.${P}`>
        : never;
    }[keyof T];

export interface MrcBoundingBox {
  element: string;
  height: number;
  left: number;
  score: number;
  top: number;
  width: number;
}

export type MrcExtractionAlert = {
  alert_type?: 'info' | 'warning' | string;
  source?: unknown;
  text?: string;
};

export type MrcExtractionResultCandidate = {
  boundingBox?: MrcBoundingBox;
  candidateAlerts?: MrcExtractionAlert[];
  containsHandwriting?: boolean;
  isValid?: boolean;
  matchConfidence?: number;
  pageNum?: number;
  rawText?: string;
  rawValue?: string | number | unknown;
  textConfidence?: number;
  value?: string | number | unknown;
};

export type MrcExtractionResultItem = {
  alerts?: MrcExtractionAlert[];
  expectedType?: 'string' | 'numeric' | 'date' | string;
  id: string;
} & MrcExtractionResultCandidate;

export type DatapointHistory = {
  updated_at: string;
  updated_by: User;
  value?: unknown;
};

export type Datapoint = {
  status?: 'accepted' | 'declined' | string;
  updated_at: string;
  updated_by: User;
  value?: unknown;
  history?: DatapointHistory[];
};

export type DataExtractionData = {
  created_at?: string;
  created_by?: string;

  adapters: string;
  data: MrcExtractionResultItem[];
  id: string;
  status: MrcExtractionResult | undefined;
  file_name: string;
  datapoints?: Record<string, Datapoint>;
  raw_data: unknown;

  updated_at?: string;
  updated_by?: User;
};

export enum EndorsementPremiumChange {
  ADDITION = 'Additional premium',
  RETURN = 'Return premium',
  NONE = 'Nil change to premium',
}

export type GroupedClauses = Record<ClauseTypes, ResourceBlock[]>;
export type BlockMentionValues = Record<string, string | number>;

export type ClauseListKey =
  | 'list_of_condition'
  | 'list_of_endorsement'
  | 'list_of_exclusion'
  | 'list_of_express_warranties'
  | 'list_of_standard_warranties'
  | 'list_of_wording';

export enum AuditLogChangeType {
  FORM_UPDATED = 'FORM_UPDATED',
  STATUS_UPDATED = 'STATUS_UPDATED',
  BLOCK_UPDATED = 'BLOCK_UPDATED',
  BLOCK_CREATED = 'BLOCK_CREATED',
  BLOCK_DELETED = 'BLOCK_DELETED',
  SUBMISSION_CREATED = 'SUBMISSION_CREATED',
  TEMPLATE_CREATED = 'TEMPLATE_CREATED',
  ENDORSEMENT_CREATED = 'ENDORSEMENT_CREATED',
  COMMENTS_THREAD_RESOLVED = 'COMMENTS_THREAD_RESOLVED',
  COMMENT_ADDED = 'COMMENT_ADDED',
  RENEWAL_CREATED = 'RENEWAL_CREATED',
  BASE_TEMPLATE_CHANGED = 'BASE_TEMPLATE_CHANGED',
}

export interface EndorsementSummary {
  blocksExcludedFromPreview: string[];
  layout: string[];
  sections: ResourceSection[];
}

export enum AuthErrorCode {
  NoCookieHeader = 303,
  NoSessionIdInCookie = 304,
  NoCompanyIdHeader = 255,
  NoAliasIdHeader = 256,
  InvalidEmailFormatForUser = 100,
  InvalidUUIDFormatForCompanyId = 101,
  CouldNotReadFromSessionsDatabase = 200,
  SessionNotFound = 201,
  AliasIdMismatch = 234,
  CouldNotParseStateItem = 236, // TODO: Confirm if this is fine
  SessionExpiredWithAbsoluteTimeout = 202,
  SessionExpiredWithIdleTimeout = 203,
  InvalidAccessToken = 205,
  CouldNotRefreshToken = 204,
  CompanyIdNotFound = 106,
  CouldNotUpdateLastActiveTime = 299,
  CouldNotUpdateSessionsDatabase = 257,
  CouldNotReadFromAuthFlowDatabase = 103,
  CouldNotWriteToSessionsDatabase = 107,
  CouldNotSaveIntoAuthFlowDatabase = 102,
  NoStateFoundInAuthFlowDatabase = 111,
  NoSubOnToken = 666,
  UserEnrichmentFailed = 900,
  CouldNotDeleteSession = 110,
  CouldNotQuerySessionData = 108,
  MissingEnvVariables = 700,
  ExpiredToken = 701,
  MissingCompanyIdOrUserOnClient = 705,
  FailedToGetAuthRedirectUrl = 702,
  FailedToExchangeAuthCode = 703,
  FailedAuthWithoutServerCode = 704,
  NoAuthorizationHeader = 707,
  InvalidTokenRolesOrScopes = 722,
}

export type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';
