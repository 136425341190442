import type { FC } from 'react';
import React, { Fragment } from 'react';
import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { useEntityStore } from '@ContractBuilder/store';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

interface UnsavedChangesModalProps {
  isSaveChangesDisabled?: boolean;
  isOpen: boolean;
  onCancel: () => void;
  onDisregardChanges: () => void;
  onSaveChanges: () => void | Promise<false | void>;
  saveChangesDisabledTooltip?: string;
  modalText?: string;
}

export const UnsavedChangesModal: FC<UnsavedChangesModalProps> = ({
  isSaveChangesDisabled = false,
  isOpen,
  onCancel,
  onDisregardChanges,
  onSaveChanges,
  saveChangesDisabledTooltip,
  modalText = 'Would you like to save your changes before leaving?',
}) => {
  const { isLoading } = useEntityStore(({ isLoading }) => ({ isLoading }));

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx('fixed inset-0 overflow-y-auto', MODAL_Z_INDEX)}
        data-testid="unsaved-changes-modal"
        onClose={onCancel}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-info-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block w-[600px] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-info-400 hover:text-info-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  onClick={onCancel}
                >
                  <span className="sr-only">Cancel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-info-800">
                    You have unsaved changes
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm font-normal leading-5 text-info-500">{modalText}</p>
                  </div>
                </div>
              </div>
              <div className="mt-12 items-center justify-end gap-x-2.5 sm:mt-10 sm:flex">
                <Button
                  className="ml-14 mr-auto h-auto px-4 py-2"
                  onClick={onDisregardChanges}
                  kind="secondary"
                  size="sm"
                  isDisabled={isLoading}
                >
                  Discard changes
                </Button>
                <Button
                  className="h-auto px-4 py-2"
                  onClick={onCancel}
                  kind="secondary"
                  size="sm"
                  isDisabled={isLoading}
                >
                  Cancel
                </Button>
                <Tooltip content={saveChangesDisabledTooltip} disabled={!isSaveChangesDisabled}>
                  <div className="w-fit">
                    <Button
                      className="h-auto px-4 py-2"
                      isDisabled={isSaveChangesDisabled}
                      kind="primary"
                      loading={isLoading}
                      onClick={onSaveChanges}
                      size="sm"
                    >
                      Save changes
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
